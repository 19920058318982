* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-weight: 550;
}

.top_section {
  display: flex;
  align-items: center;
}

.top_section img {
  max-width: 30vw;
}

.sidebar {
  background: #8b0000;
  color: #ffd700;
  width: 30vw;
  transition: all 0.5s;
}

.link {
  display: flex;
  color: #ff4500;
  padding: 10px 15px;
  transition: all 0.5s;
  text-decoration: none !important;
}

.link:hover {
  background: #ff69b4;
  color: #8b0000;
  transition: all 0.5s;
  text-decoration: none !important;
}

.active {
  background: #dc143c;
  color: #ffffff;
}

.link.active2 {
  background-color: #ff6347;
}

.icon,
.link_text {
  text-decoration: none !important;
  width: 80px;
}

#bgc {
  position: relative;
  background-image: linear-gradient(
      rgba(53, 53, 35, 0.7),
      rgba(38, 36, 24, 0.9)
    ),
    url("./image/bgc.jpg");
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  place-items: center;
  overflow: auto;
}

.left_show {
  position: fixed;
}

.right_show {
  position: absolute;
  max-width: 70vw;
  top: 0;
  left: 30vw;
  overflow: hidden;
}

.card_margin {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 220px;
  margin: 0 auto;
}

.Pagination_margin {
  padding-top: 10px;
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
}
